'use client'

import { createContext, useContext, useEffect, useRef } from 'react'

import { usePathname } from '@/i18n'

interface RouterHistoryProviderProps extends React.PropsWithChildren {}

interface RouterHistoryContextProps {
  previousPath: string | undefined
}

export const RouterHistoryContext = createContext<RouterHistoryContextProps>({
  previousPath: undefined,
})

export const RouterHistoryProvider = ({ children }: RouterHistoryProviderProps) => {
  const pathname = usePathname()
  const previousPathRef = useRef('')

  useEffect(() => {
    previousPathRef.current = pathname
  }, [pathname])

  const previousPath = previousPathRef.current

  return <RouterHistoryContext.Provider value={{ previousPath }}>{children}</RouterHistoryContext.Provider>
}

export const useRouterHistoryContext = () => {
  const value = useContext(RouterHistoryContext)
  return value
}

export default RouterHistoryContext
