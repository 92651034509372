import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

export const generateSystemPromptText = (name: string, role: UserRoles, defaultPromptText: string) => {
  return (
    defaultPromptText +
    ` User's name is '${name}' ` +
    ` and user's role is ${role === 'Family Member' ? 'parent' : role}.` +
    ' You should skip step where you ask about role.' +
    ' You should address user by name.' +
    ' If users language is not english you should follow next steps:' +
    ' 1. Translate question to english, ' +
    ' 2. then find a matching answers, ' +
    ' 3. translate it back to language that user uses so you can respond.'
  )
}

export const createNewChat = (chatAPI: string, chatId: string, systemPromptName: string) => {
  const MODELS = ['gpt-4o', 'gpt-3.5-turbo', 'gpt-4o-mini']
  // Check if we'll change this ever.
  const modelName = MODELS[0]
  const numOfSource = 3

  return fetch(`${chatAPI}/create/${chatId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      system_prompt: systemPromptName,
      numOfSource: numOfSource,
      name: 'test',
      title: 'test',
      modelName: modelName,
    }),
  })
}

type TranslationValues = Record<string, string | number>
type Formats = Record<string, Intl.NumberFormatOptions | Intl.DateTimeFormatOptions>

export type TranslationFunction = (key: string, values?: TranslationValues, formats?: Partial<Formats>) => string

export const generatePrompts = (translations: TranslationFunction) => {
  const prompts: { text: string }[] = []
  prompts.push({ text: translations('firstPrompt') })
  prompts.push({ text: translations('secondPrompt') })
  prompts.push({ text: translations('thirdPrompt') })
  prompts.push({ text: translations('fourthPrompt') })
  prompts.push({ text: translations('fifthPrompt') })
  prompts.push({ text: translations('sixthPrompt') })

  return prompts
}

export type TextQuestion = {
  id: string
  label: string
  guidanceBullets: {
    id: string
    label: string
  }[]
}

export type CheckboxGroupType = {
  value: string
  label: string
}

export const generateTextQuestions = (t: TranslationFunction) => {
  const textQuestions: TextQuestion[] = [
    {
      id: 'distinctive_qualities',
      label: t('distinctiveQualities'),
      guidanceBullets: [
        {
          id: 'notable_quality',
          label: t('notableQuality'),
        },
        {
          id: 'community',
          label: t('community'),
        },
        {
          id: 'describing_applicant',
          label: t('describingApplicant'),
        },
      ],
    },
    {
      id: 'academic_interests',
      label: t('academicInterests'),
      guidanceBullets: [
        {
          id: 'student_history',
          label: t('studentHistory'),
        },
        {
          id: 'specific_circumstances',
          label: t('specificCircumstances'),
        },
        {
          id: 'challenging_history',
          label: t('challengingHistory'),
        },
        {
          id: 'words_to_describe',
          label: t('wordsToDescribe'),
        },
        {
          id: 'awards',
          label: t('awards'),
        },
      ],
    },
    {
      id: 'activities_and_interests',
      label: t('activitiesAndInterest'),
      guidanceBullets: [
        {
          id: 'student_involvement_at_school',
          label: t('studentInvolvementAtSchool'),
        },
        {
          id: 'student_involvement_outside_school',
          label: t('studentInvolvementOutsideSchool'),
        },
        {
          id: 'impact',
          label: t('impact'),
        },
        {
          id: 'recognition_for_involvement',
          label: t('recognitionForInvolvement'),
        },
        {
          id: 'leadership',
          label: t('leadership'),
        },
      ],
    },
    {
      id: 'personal_history_and_family_context',
      label: t('personalHistoryAndFamilyContext'),
      guidanceBullets: [
        {
          id: 'family_dyncamics',
          label: t('familyDynamics'),
        },
        {
          id: 'home_responsibilities',
          label: t('homeResponsibilities'),
        },
        {
          id: 'other_factors',
          label: t('otherFactors'),
        },
      ],
    },
    {
      id: 'summary',
      label: t('summary'),
      guidanceBullets: [
        {
          id: 'final_snapshot_no_bullet',
          label: t('finalSnapshot'),
        },
        {
          id: 'student_story',
          label: t('studentStory'),
        },
        {
          id: 'students_hopes',
          label: t('studentsHopes'),
        },
        {
          id: 'applicant_key_takeaway',
          label: t('applicantKeyTakeaway'),
        },
        {
          id: 'is_student_highly_recommended',
          label: t('isStudentHighlyRecommended'),
        },
      ],
    },
  ]
  return textQuestions
}

export const generateCheckboxQuestions = (t: TranslationFunction) => {
  const formattingHints: CheckboxGroupType[] = [
    { value: 'bullet_lists', label: t('bulletLists') },
    { value: 'narrative', label: t('narrative') },
  ]

  const toneHints: CheckboxGroupType[] = [
    { value: 'formal', label: t('formal') },
    { value: 'informal', label: t('informal') },
  ]

  const styleHints: CheckboxGroupType[] = [
    { value: 'conversational', label: t('conversational') },
    { value: 'storytelling', label: t('storytelling') },
    { value: 'keep_it_short', label: t('keepItShort') },
  ]

  const gender: CheckboxGroupType[] = [
    { value: 'male', label: t('male') },
    { value: 'female', label: t('female') },
    { value: 'neutral', label: t('neutral') },
  ]

  const closing: CheckboxGroupType[] = [
    { value: 'respectfully_submitted', label: t('respectfullySubmitted') },
    { value: 'yours_truly', label: t('yoursTruly') },
    { value: 'sincerely', label: t('sincerely') },
    { value: 'custom', label: t('custom') },
  ]

  const checkboxGroups: {
    id: 'formattingHints' | 'toneHints' | 'styleHints' | 'gender' | 'closing'
    label: string
    components: CheckboxGroupType[]
  }[] = [
    {
      id: 'formattingHints',
      label: t('formattingHints'),
      components: formattingHints,
    },
    {
      id: 'toneHints',
      label: t('toneHints'),
      components: toneHints,
    },
    {
      id: 'styleHints',
      label: t('styleHints'),
      components: styleHints,
    },
    {
      id: 'gender',
      label: t('gender'),
      components: gender,
    },
    {
      id: 'closing',
      label: t('closing'),
      components: closing,
    },
  ]

  return checkboxGroups
}
